<template>
  <div class="eden-unauthorized">
    <h1>Unauthorized!</h1>
    <h3>You don't have access to view this page.</h3>
    <div class="eden-unauthorized__actions">
      <el-button type="outline" @click="goBack">Go back</el-button>
      <el-button type="primary" @click="goHome">Home</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Unauthorized",
  computed: {
    role() {
      return this.$store.getters.user_role;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goHome() {
      if (["steward", "ofa"].includes(this.role)) {
        this.$router.push({ name: "production-provisions.index" });
      } else {
        this.$router.push({ name: "customers.index" });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.eden-unauthorized {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 3.5rem;
    color: #21312a;
    margin-bottom: 20px;
    line-height: 0.6;
    text-transform: uppercase;
  }

  h3 {
    font-size: 2rem;
  }

  &__actions {
    margin-top: 50px;
    display: flex;
    align-items: center;
  }
}
</style>
